import { useSelector } from "react-redux"
import { useRouter } from "next/router"
import { useMediaQuery } from "react-responsive"

import { Button, ButtonHierarchy } from "@/components/styleguide/Button"
import { selectIsAuthenticated } from "@/store/selectors/user"
import { Size, Theme } from "@/types/system"
import { ROUTES } from "@/lib/routes"
import { constants } from "@/lib/constants"
import { InternalLink } from '@/components/styleguide/Link'
import { NavDropdown } from "@/components/atomic/NavDropdown"
import { isProd } from "@/lib/utilities/system"

import styles from './header.module.scss'

export type AccountSectionProps = {
  theme: Theme
  onToggleAccountNav: (isOpen: boolean) => void
  onClickToSignUp: () => void
  onClickToLogin: () => void
}

export const AccountSection = ({ theme, onToggleAccountNav, onClickToSignUp, onClickToLogin }: AccountSectionProps) => {
  const router = useRouter()
  const isDesktop = useMediaQuery({ minWidth: 992 })
  const isAuthenticated = useSelector(selectIsAuthenticated)

  if (isAuthenticated) {
    return (
      <>
        <InternalLink
          size={Size.Medium}
          hierarchy={ButtonHierarchy.Text}
          href={ROUTES.priceAlertsPage.url}
          theme={theme}
          className={`${
            ROUTES.priceAlertsPage.regex.test(router.asPath.split('?')[0])
              ? styles.active
              : ''
          }${!isProd() ? ' header__price-alerts-button' : ''}`}
          icon="Bell"
          hasTransition
          hasBackground
          hasPadding>
          {isDesktop && 'Price alerts'}
        </InternalLink>
        <NavDropdown
          item={constants.navigation[0]}
          onToggleNav={onToggleAccountNav}
          isDark={theme === Theme.dark}
        />
      </>
    )
  }

  return (
    <>
    {isDesktop && 
      <Button
        size={Size.Medium}
        hierarchy={ButtonHierarchy.Text}
        onClick={onClickToSignUp}
        className={!isProd() ? 'header__sign-up-button' : ''}
        theme={theme}
        hasPadding
        hasTransition
        hasBackground
        label="Sign Up"
      />
    }
      <Button
        size={Size.Medium}
        hierarchy={ButtonHierarchy.Text}
        onClick={onClickToLogin}
        className={!isProd() ? 'header__log-in-button' : ''}
        theme={theme}
        hasPadding
        hasTransition
        hasBackground
        label="Log In"
      />
    </>
  )
}