import Link, { LinkProps } from 'next/link'
import React, { ComponentPropsWithoutRef, ReactNode } from 'react'
import dynamic from 'next/dynamic'

import buttonStyles from '../Button/button.module.scss'
import linkStyles from './link.module.scss'
import { ButtonHierarchy } from '@/components/styleguide/Button'
import { BaseLinkProps } from '@/components/styleguide/Link/ExternalLink'
import { Size, Theme } from '@/types/system'

const Icon = dynamic(() => import('@/components/styleguide/Icon'))

export interface InternalLinkProps extends LinkProps {
  children: ReactNode
  onClick?: () => void
  anchorProps?: ComponentPropsWithoutRef<'a'>
}

export const InternalLink = ({
  children,
  hierarchy = ButtonHierarchy.Borderless,
  size = Size.Medium,
  className = '',
  icon,
  hasPadding = false,
  hasBackground = false,
  hasUnderline = false,
  hasTransition = false,
  isFullWidth = false,
  isSquare = false,
  theme = Theme.light,
  onClick,
  ...restProps
}: InternalLinkProps & BaseLinkProps) => {
  return (
    <Link
      {...restProps}
      passHref>
      <a
        className={`${buttonStyles.main} ${linkStyles.link} ${className} ${isFullWidth ? linkStyles['is-full-width'] : ''}`}
        data-hierarchy={hierarchy}
        data-is-square={isSquare}
        data-has-underline={hasUnderline}
        data-has-background={hasBackground}
        data-has-transition={hasTransition}
        data-has-padding={hasPadding}
        data-has-icon={!!icon}
        data-size={size}
        onClick={onClick}
        data-theme={theme}>
        {icon && (
          <Icon
            name={icon}
            size={size === Size.Large ? Size.Large : Size.Medium}
          />
        )}
        {children}
      </a>
    </Link>
  )
}
