import { ButtonHierarchy } from '@/components/styleguide/Button'
import { IconProps } from '@/components/styleguide/Icon/Icon'
import { Size, Theme } from '@/types/system'
import React, { ComponentProps } from 'react'
import dynamic from 'next/dynamic'

import buttonStyles from '../Button/button.module.scss'
import linkStyles from './link.module.scss'

const Icon = dynamic(() => import('@/components/styleguide/Icon'))

export type BaseLinkProps = {
  hierarchy: ButtonHierarchy
  size: Size
  icon?: IconProps['name']
  hasPadding?: boolean
  hasBackground?: boolean
  theme?: Theme
  hasUnderline?: boolean
  hasTransition?: boolean
  isFullWidth?: boolean
  isSquare?: boolean
  className?: string
}

export type ExternalLinkProps = ComponentProps<'a'> & BaseLinkProps

export const ExternalLink = ({
  children,
  hierarchy = ButtonHierarchy.Borderless,
  size = Size.Medium,
  className = '',
  icon,
  hasPadding = false,
  hasBackground = false,
  theme = Theme.light,
  hasUnderline = false,
  hasTransition = false,
  isFullWidth = false,
  isSquare = false,
  ...restProps
}: ExternalLinkProps) => {
  return (
    <a
      className={`${buttonStyles.main} ${className} ${isFullWidth ? linkStyles['is-full-width'] : ''}`}
      data-hierarchy={hierarchy}
      data-is-square={isSquare}
      data-has-underline={hasUnderline}
      data-has-background={hasBackground}
      data-has-transition={hasTransition}
      data-has-padding={hasPadding}
      data-size={size}
      data-theme={theme}
      {...restProps}>
      {icon && (
        <Icon
          name={icon}
          size={size === Size.Large ? Size.Large : Size.Medium}
        />
      )}
      {children}
    </a>
  )
}
