import dynamic from 'next/dynamic'
import React, { ReactNode } from 'react'

import { IconProps } from '../Icon'
import styles from './button.module.scss'
import { Size, SizeType, Theme, ThemeType } from '@/types/system'

const Icon = dynamic(() => import('@/components/styleguide/Icon'))

export enum ButtonHierarchy {
  Bezeled = 'bezeled',
  BezeledGray = 'bezeled-gray',
  Borderless = 'borderless',
  BorderlessGray = 'borderless-gray',
  ChipChoice = 'chip-choice',
  ChipFilter = 'chip-filter',
  Filled = 'filled',
  Outline = 'outline',
  Success = 'success',
  TabChoice = 'tab-choice',
  Text = 'text',
}
export interface ButtonComponentProps extends React.ComponentPropsWithoutRef<'button'> {
  size: SizeType
  hierarchy: ButtonHierarchy
  hasBackground?: boolean
  isSelected?: boolean
  hasPadding?: boolean
  isSquare?: boolean
  theme?: ThemeType
  ariaLabel?: React.AriaAttributes['aria-label']
  label?: string
  icon?: IconProps['name'] | ''
  iconPosition?: 'left' | 'right'
  leadingIcon?: IconProps['name'] | ''
  trailingIcon?: IconProps['name'] | ''
  hasTransition?: boolean
  hasUnderline?: boolean
  isLoading?: boolean
  children?: ReactNode
  hasIcon?: boolean
}

export const Button = ({
  hierarchy,
  disabled = false,
  label,
  size,
  icon = '',
  iconPosition = 'left',
  leadingIcon = '',
  trailingIcon = '',
  isSelected = false,
  hasBackground = true,
  hasPadding = true,
  hasTransition = true,
  hasUnderline = false,
  theme = Theme.light,
  ariaLabel,
  isSquare = false,
  isLoading = false,
  hasIcon = false,
  className = '',
  type = 'button',
  children,
  ...restProps
}: ButtonComponentProps) => {
  const commonProps = {
    className: `${styles.main} ${className}`,
    'data-is-square': (icon && !label) || isSquare ? 'true' : 'false',
    'data-hierarchy': hierarchy,
    'data-selected': isSelected,
    'data-size': size,
    'data-has-transition': isLoading ? false : hasTransition,
    'data-has-icon': hasIcon || !!icon || !!leadingIcon || !!trailingIcon,
    'data-icon-position': iconPosition,
    'data-has-background': hasBackground,
    'data-has-padding': hasPadding,
    'data-theme': theme,
    'aria-label': ariaLabel ? ariaLabel : icon ? icon : label,
    'data-has-underline': hasUnderline,
    'data-is-loading': isLoading,
    disabled: disabled,
  }

  return (
    <button
      {...restProps}
      {...commonProps}
      onClick={e => {
        if (isLoading) {
          return
        }
        if (restProps.onClick) {
          restProps.onClick(e)
        }
      }}
      type={type}>
      {leadingIcon && !isLoading && (
        <Icon
          name={leadingIcon}
          size={size === Size.Large ? Size.Large : Size.Medium}
        />
      )}
      {iconPosition === 'right' && !isLoading && label}
      {icon && !isLoading && (
        <Icon
          name={icon}
          size={size === Size.Large ? Size.Large : Size.Medium}
        />
      )}
      {iconPosition === 'left' && !isLoading && label}
      {isLoading && (
        <Icon
          name="Loader2"
          size={size === Size.Large ? Size.Large : Size.Medium}
        />
      )}
          {trailingIcon && !isLoading && (
        <Icon
          name={trailingIcon}
          size={size === Size.Large ? Size.Large : Size.Medium}
        />
      )}
      {children}
    </button>
  )
}
