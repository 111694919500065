import { useSelector } from 'react-redux'

import { constants } from '../constants'
import { isProd, storageAvailable } from '../utilities/system'
import { selectExperiment } from '@/store/selectors/user'

export function useExperiment(): [string, string] {
  const experiment = useSelector(selectExperiment)
  const experimentOverride = getExperimentOverride()

  return isProd() || !experimentOverride
    ? [experiment.group, experiment.name]
    : [experimentOverride.group, experimentOverride.name]
}

function getExperimentOverride(): { group: string; name: string } | null {
  if (storageAvailable('localStorage')) {
    const content = localStorage.getItem(constants.localStorageKey.experimentOverride)
    return content ? JSON.parse(content) : null
  }

  return null
}
