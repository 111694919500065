import { signOut, getCurrentUser } from 'aws-amplify/auth'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { useDispatch, useSelector } from 'react-redux'
import MediaQuery from 'react-responsive'

import { AccountNavigation } from '../AccountNavigation'
import { Avatar } from '@/components/atomic/Avatar'
import { addToDataLayer } from '@/lib/utilities/vehicle'
import { clearComparisonList, clearLocoSpaceState } from '@/store/reducers/locoSpace'
import { storeSavedSearchReference } from '@/store/reducers/search'
import {
  clearRedirectUrl,
  clearUserStateExceptRedirectUrl,
  storeAvatar,
  storeIsUserAuthenticated,
  storeRedirectUrl,
  storeUsedSocialLogin,
  storeUserName,
} from '@/store/reducers/user'
import { selectUsedSocialLogin } from '@/store/selectors/user'
import { Size } from '@/types/system'

const Icon = dynamic(() => import('@/components/styleguide/Icon'))

export const NavDropdown = ({ item, onToggleNav, isDark }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const isSocialLogin = useSelector(selectUsedSocialLogin)
  const [isOpen, setIsOpen] = useState(false)

  const toggleNav = () => {
    if (onToggleNav && !isOpen) {
      onToggleNav()
    }
    setIsOpen(isOpen => !isOpen)
  }

  const closeNav = () => {
    setIsOpen(false)
  }

  const ref = useDetectClickOutside({ onTriggered: closeNav })
  const onSignOut = () => {
    dispatch(storeRedirectUrl(router.asPath.split('?')[0]))

    signOut()
      .then(() => {
        updateUserStatus()
        addToDataLayer({
          event: 'log-out',
          action: 'Logged Out',
        })
        dispatch(storeUsedSocialLogin(false))
        doRedirect(router.asPath.split('?')[0])
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log('error signing out...', err))
  }

  const doRedirect = url => {
    if (isSocialLogin) {
      router.push(url)
      setTimeout(() => {
        router.push(url)
        dispatch(clearRedirectUrl())
      }, 200)
    }
  }

  const updateUserStatus = () => {
    getCurrentUser()
      .then(user => {
        dispatch(storeAvatar(''))
        dispatch(storeUserName(user.username))
        dispatch(storeIsUserAuthenticated(true))
      })
      .catch(() => {
        dispatch(clearUserStateExceptRedirectUrl())
        dispatch(clearLocoSpaceState())
        dispatch(clearComparisonList())
        dispatch(storeSavedSearchReference(''))
      })
  }

  return (
    <>
      <div
        className="dropdown"
        ref={ref}>
        <button
          className="nav-link dropdown--navigation"
          type="button"
          id={`${item.label.replace(' ', '')}DropdownMenuButton`}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : 'false'}
          onClick={toggleNav}>
          <Avatar size={34} />
          <span className="sr-only">My Account</span>
          <Icon
            name="ChevronDown"
            size={Size.Medium}
            customClass={isDark ? 'dropdown__navigation--dark' : 'dropdown__navigation--light'}
          />
        </button>
        <MediaQuery minWidth={992}>
          <Fade
            duration={300}
            triggerOnce>
            <div
              className={`dropdown-menu dropdown-menu-right ${item.label
                .split(' ')
                .join('-')
                .toLowerCase()}-dropdown${isOpen ? ' show' : ''}`}
              aria-labelledby={`${item.label.replace(' ', '')}DropdownMenuButton`}>
              <div className="dropdown__column">
                {item.subnav
                  .filter(nav => nav.category !== 'MANUFACTURER')
                  .map((sub, index) => (
                    <Link
                      href={sub.slug}
                      key={`${sub.slug}${index}`}
                      prefetch={false}>
                      <a
                        className={`nav-link${router.asPath.includes(sub.slug) ? ' active' : ''}`}
                        onClick={toggleNav}>
                        {sub.label}
                      </a>
                    </Link>
                  ))}
              </div>
              <div className="dropdown-divider"></div>
              <button
                className="nav-link"
                type="button"
                onClick={onSignOut}>
                Sign out
              </button>
            </div>
          </Fade>
        </MediaQuery>
      </div>
      <MediaQuery maxWidth={991}>
        <AccountNavigation
          isOpen={isOpen}
          onSignOut={onSignOut}
        />
      </MediaQuery>
    </>
  )
}
