import Link from 'next/link'
import { useRouter } from 'next/router'
import AnimateHeight from 'react-animate-height'

import { ROUTES } from '../../../lib/routes'
import styles from './account-navigation.module.scss'

export const AccountNavigation = ({ isOpen, onSignOut }) => {
  const router = useRouter()

  return (
    <div className={styles.container}>
      <AnimateHeight
        animateOpacity
        height={isOpen ? 'auto' : 0}
        className={`${styles['navbar-collapse']} ${isOpen ? styles['is-open'] : ''}`}
        duration={400}>
        <ul className={styles['navbar-nav']}>
          <div className={styles['mobile-nav']}>
            <li className={styles['nav-item']}>
              <Link
                href={ROUTES.accountDetailsPage.url}
                prefetch={false}>
                <a
                  className={`${styles['nav-link']} ${
                    router.asPath.includes(ROUTES.accountDetailsPage.url) ? ' active' : ''
                  }`}>
                  Account details
                </a>
              </Link>
            </li>
            <li className={styles['nav-item']}>
              <Link
                href={ROUTES.priceAlertsPage.url}
                prefetch={false}>
                <a
                  className={`${styles['nav-link']} ${
                    router.asPath.includes(ROUTES.priceAlertsPage.url) ? ' active' : ''
                  }`}>
                  Price alerts
                </a>
              </Link>
            </li>
            <li className={styles['nav-item']}>
              <Link
                href={ROUTES.savedSearchPage.url}
                prefetch={false}>
                <a
                  className={`${styles['nav-link']} ${
                    router.asPath.includes(ROUTES.savedSearchPage.url) ? ' active' : ''
                  }`}>
                  Saved searches
                </a>
              </Link>
            </li>
            <li className={styles['nav-item']}>
              <Link
                href={ROUTES.enquiriesPage.url}
                prefetch={false}>
                <a
                  className={`${styles['nav-link']} ${
                    router.asPath.includes(ROUTES.enquiriesPage.url) ? ' active' : ''
                  }`}>
                  My enquiries
                </a>
              </Link>
            </li>
            <li className={styles['nav-item']}>
              <Link
                href={ROUTES.referAFriendPage.url}
                prefetch={false}>
                <a
                  className={`${styles['nav-link']} ${
                    router.asPath.includes(ROUTES.referAFriendPage.url) ? ' active' : ''
                  }`}>
                  Refer a friend
                </a>
              </Link>
            </li>
            <li className={styles['nav-item']}>
              <Link
                href={ROUTES.rewardsPage.url}
                prefetch={false}>
                <a
                  className={`${styles['nav-link']} ${
                    router.asPath.includes(ROUTES.rewardsPage.url) ? ' active' : ''
                  }`}>
                  Rewards
                </a>
              </Link>
            </li>
          </div>
          <div className={styles['dropdown-divider']}></div>
          <button
            className={`${styles['nav-link']} ${styles['sign-out']}`}
            type="button"
            onClick={onSignOut}>
            Sign out
          </button>
        </ul>
      </AnimateHeight>
    </div>
  )
}
